.btn-signin {
  width: 400px;
  height: 20px;
}

#customBtn {
  display: inline-block;
  background: white;
  color: #444;
  width: 190px;
  border-radius: 5px;
  border: thin solid #888;
  box-shadow: 1px 1px 1px grey;
  white-space: nowrap;
}
#customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  background: url('/identity/sign-in/g-normal.png') transparent 5px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  padding-left: 42px;
  padding-right: 42px;
  font-size: 14px;
  font-weight: bold;
  /* Use the Roboto font that is loaded in the <head> */
  font-family: 'Roboto', sans-serif;
}

.content {
  padding-left: 100px;
}

.profileName {
  padding-right: 20px;
  padding-bottom: 5px;
  vertical-align: bottom;
}

.navbar-header{
  margin-left:55px;
  padding-left: 55px;
  width:100%;
}


.message-creator__content {
  margin-bottom: 7px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  height: 90px;
}

.message-creator__post-button {
  border: 0;
  padding: 5px 30px;
  background-color: #049dbf;
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 7px;
}

.message {
  border-color: #ededed;
  border-style: solid;
  border-width: 1px;
  border-bottom-width: 0;
  padding: 15px;
}

.message:last-child {
  border-bottom-width: 1px;
}

.message__author-line {
  padding-bottom: 4px;
}

.message__author {
  display: inline-block;
  font-weight: bold;
}

.message__created-at {
  display: inline-block;
  font-size: 60%;
  color: #9a9a9a;
}

.message__created-at::before {
  content: "\2022";
  padding-left: 5px;
  padding-right: 5px;
}

.message__content {

}

.dashboarddiv {
  margin: 5px;
  width: 20px;
}


.dashboardcenter {
  margin-left: 5px;
  width:100%;
text-align: center;
}
.dashboardleft {
  float: left;
  margin-left: 5px;
  width:300px;
  height: 50px;
}


.dashboardsmallleft {
  float: left;
  margin-left: 20px;
  width: 200px;
  height: 10px;
  vertical-align: middle
}
.dashboardreklamationleft {
  float: left;
  margin-top:10px;
  margin-left: 20px;
  width: 200px;
  height: 10px;
  vertical-align: middle
}
.dashboardright {
  float: right;
  margin-right: 5px;
  width: 100px;
  position: absolute; right: 0; bottom: 0;
  margin-bottom: 15px;
}
.dashboardtopright {
  float: right;
  margin-right: 5px;
  width: 130px;
  vertical-align: top; /* here */
  position: absolute; right: 0; top: 0;
  margin-top: 10px;
}

.dashboardtoprightbelow {
  float: right;
  margin-right: 5px;
  width: 70px;
  vertical-align: top; /* here */
  position: absolute; right: 0; top: 0;
  margin-top: 30px;
}
.dashboardtoprightsmall {
  float: right;
  margin-right: 5px;
  width: 50px;
  vertical-align: top; /* here */
  position: absolute; right: 0; top: 0;
  margin-top: 10px;
}

.dashboardsmallright {
  float: right;
  vertical-align: center;
  margin-right: 20px;
}
.dashboardreklamationer {
  float: right;
  width : 100%;
  text-align: right;
  vertical-align: center;
  margin-right: 20px;
  margin-top: 0px;
}
.dashboardreklamationerright {
  display: block;
  color: black;
  vertical-align: center;
  margin-right: 20px;
  margin-top: -15px;
}
.deliverylefttable {
  clear: both; 
  margin-left: 5px;
  width:100%;
}

.deliveryleft {
  float: left;
  margin-left: 5px;
  width:50%;
}

.deliveryright {
  float: right;
  margin-right: 15px;
  width: 700px;
}
.btn-padding {
  margin-right: 15px;
}

.btn-smallpadding
{
  margin-top: -10px;
  margin-bottom: -10px;
  margin-left: 10px;
}
.paddingleft {
  padding-left: 10px;
}
.marginleft {
  margin-left: 10px;
}
.commentPadding {
  padding-left: 50px;
  vertical-align: top;
  text-align: left;
}
#reklamationerTable {
  width: 100%;
}
.paddingmore{
  width: 300px;
padding-left: 0px;
}
.fordonDiv {
  width: 250px
}
.detailsText {
  font-size: 16px;
}

.precisioncontainer {
  display: block;;
  margin-left: 5px;
  width: 100%;
  position: absolute; left: 0; bottom: 0;
  margin-top: 15px;
}

.precisionitem {
  border: 1px solid #DDDDDD;
  margin: 2px;
  padding: 5px;
  background: #F5F5F5; 
  font-size: 10px;
  width: 75px;
}

.precisionitemweek {
  border: 1px solid #DDDDDD;
  margin: 2px;
  padding: 5px;
  background:grey; 
  font-size: 10px;
  width: 90px;
}
.precisionitemweek p{
  margin-bottom: 3px; 
  color: #fff;
  }
.precisionitem p{
margin-bottom: 3px; 
}

.precisionitemlargetext {
  font-size: 15px;
}
.precisionitemmediumtext {
  font-size: 13px;
}


@media (max-width: 1450px) {


  .precisionitem {
    border: 1px solid #DDDDDD;
    margin: 2px;
    padding: 5px;
    background: #F5F5F5; 
    font-size: 10px;
    width: 70px;
  }
  
  .precisionitemweek {
    border: 1px solid #DDDDDD;
    margin: 2px;
    padding: 5px;
    background:grey; 
    font-size: 10px;
    width: 80px;
  }
  .precisionitemweek p{
    margin-bottom: 3px; 
    color: #fff;
    }
  .precisionitem p{
  margin-bottom: 3px; 
  }
  
  .precisionitemlargetext {
    font-size: 14px;
  }
  .precisionitemmediumtext {
    font-size: 12px;
  }

}

.imageGallery {
  display: inline-block;
  margin-top: 40px;
  width: 40%
}

.imageGalleryTable {
  float:left;

}

.invoiceDiv {
  width: 1000px
}
.dashboardicon {
  vertical-align: middle;
  margin-top: 10px;
  margin-right: 10px;
}
.full {
  background: #000000;
  position: absolute;
  right: 0;
  top: 100;
  width: 100%;
  height: 90%
}

.floatRight {
  float: right;
}

.divbreak {
 clear: both;
}

.gauge {
  margin-top: -40px;
}

.circleDiv {
  margin-left: 100px;
  width: 180px;
  height: 180px;
}



.smallcircleDiv {
  margin-left: 30px;
  width: 140px;
  height: 140px;
}

.dashboardleftfleet {
  float: left;
  margin-left: 5px;
  width:400px;
}


.circleFleetDiv {
  margin-left: 10px;
  width: 120px;
  height: 120px;
  vertical-align: top
}



.fleettopright{
 margin-left: 30px;
}


@media (max-width: 1450px) {
  .smallcircleDiv {
    margin-left: 20px;
    width: 120px;
    height: 120px;
  }

  .circleFleetDiv {
    margin-left: 20px;
    width: 150px;
    height: 150px;
  }
  
}

.latestartbutton {
width: 100px;
margin: -10px
}


@media all and (max-width:480px) {
  .btn-gordoneer { width: 100%; display:block; }
}  
.pass {
  list-style-type: none;
 }
.warning {
  color: red;
}

.detailsbtn {
  font-size: 12px;
  padding: 2px;
  margin: -2px;
  text-align: center
}
.eventbtn {
  
  font-size: 12px;
  padding: 2px 10px;
  margin: -1px 10px;
  text-align: center
}

.center{
  text-align: center
}

.profileimg {
  max-width:150px
}

.vehiclelog {
  height: 400px
}

.vehiclelogpanel {
  height: 500px;
}

.eventlog {
  height: 200px
}

.eventlogpanel {
  height: 400px;
}

div.products{
  width: 100%; /* Declare your value. Can be in relative units. */

}
div.product {
 
  cursor: pointer;
  margin: 5px;
  padding: 2px;
  padding-left: 5px;
  display: inline-block;
	vertical-align: top;
  border: 1px solid #dddddd;
  width: 20%;
  height: 30px;
  color: #000;
  background: #F5F5F5;
  border: 1px solid #777;
}

div.product h4 {
  font-size: 12px;
}
div.product:hover {
  border: 1px solid #777;
  background: #DDDDDD;
}

.cover {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background-color: #ddd;
  opacity: 0.8;
}


.covercontent {
  position: absolute;
  left: 50%;
  /* make the top edge of .inner appear in the vertical center of .outer */
  top: 50%;
 
  /* move .inner up by half of its height so that its middle is in the middle of .outer */

  height: 100%;
  width: 100%;
 }

.over_map {
  position: absolute; top: 80px; right: 60px; z-index: 99; background: red; width: 250px; height: 600px;
  background-color: #ddd;
  border-radius: 5px;
  border: thin solid rgb(221, 221, 221);
  box-shadow: 1px 1px 1px grey;
  overflow-y: scroll
}

.accor  {
  margin: 0px;
  transition: 0.4s;
  cursor: pointer;
}

.head {
padding: 10px 10px;
margin: 10px 10px;
cursor: pointer;
}
.body {
padding: 0 20px;
max-height: 0;
overflow: hidden;
transition: 200ms ease-in-out;
}

.active > .body {
padding: 10px 20px;
max-height: 600px;
}

.bm-item-list { 
  color: #b8b7ad;
  padding: 0.8em;
}

.routingerror {
    color: red;
    text-shadow: 1px 1px 1px #ccc;
    font-size: 1.5em;
}

.routingwarning {
  color:#FFC300;
  text-shadow: 1px 1px 1px #ccc;
  font-size: 1.5em;
}

.Demo__github-icon {
  font-size: 24px;
}

.Demo__github-link {
  color: #262626;
  font-size: 20px;
}

.Demo__github-link:hover,
.Demo__github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.Demo__suggestion-icon {
  margin-right: 8px;
}

.fa.fa-map-marker.header {
  color: #e53935;
}

.Demo__search-input,
.Demo__search-input:focus,
.Demo__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  border: honeydew;
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.Demo__autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  border-radius: 0 0 2px 2px;
}

.Demo__suggestion-item {
  padding: 4px;
  text-align: left;
}

.Demo__spinner {
  color: #18bc9c;
  font-size: 30px;
}

.records-info {
  font-weight: bold
}

.drop{
  position: relative;
  width: 60%;
  height: 200px;
  background: rgb(244, 244, 244);
  border-width: 2px;
  border-color: rgb(135, 182, 232);
  border-style: dashed;
  border-radius: 10px;
  cursor: pointer;
}