.complaints {
  color: #fff;
}
.complaints.react-grid-item {
  background: #DB524A;
}
.precision {
  color: #fff;
}
.precision.react-grid-item {
  background: #4DBD74;
}
.antal {
  color: #fff;
}
.antal.react-grid-item {
  background: #FFCD39;
}
