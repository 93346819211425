#searchInput:focus {
    outline: none;
}
.react-switch {
    vertical-align: middle;
    margin-right: 5px;
  }

.search-result-card{
-webkit-box-shadow: 1px 1px 5px 0px rgba(217,217,217,1);
-moz-box-shadow: 1px 1px 5px 0px rgba(217,217,217,1);
box-shadow: 1px 1px 5px 0px rgba(217,217,217,1);
border-radius: 2.5px
}

.search-result-card:hover {
  -webkit-box-shadow: 1px 1px 5px 0px #174b5f;
-moz-box-shadow: 1px 1px 5px 0px #174b5f;
box-shadow: 1px 1px 5px 0px #174b5f;
  /*
  background-image: linear-gradient(to bottom right, transparent, #61b892);
*/
}