@import url(http://fonts.googleapis.com/css?family=Raleway:400,700,800);
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  height: 100%;
  background: #fff;
  color: #174B8F;
  font-family: 'Raleway', Arial, sans-serif;
}
#app {
  height: 100%;
}
a {
  color: #4e4a46;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #c94e50;
}
main {
  height: 100%;
  padding: 3em 2em;
  text-align: center;
  background: #b4bad2;
  overflow: scroll;
}
h1 {
  font-weight: 800;
  font-size: 3.75em;
}
.description {
  max-width: 20em;
  margin: 1.2em auto 1em;
  line-height: 1.3em;
  font-weight: 400;
}
.demo-buttons {
  font-size: 1em;
  max-width: 1200px;
  margin: 2em auto 3em;
  padding: 0 10em;
}
.demo-buttons a {
  display: inline-block;
  margin: 0.75em;
  padding: 1.35em 1.1em;
  width: 15em;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 800;
  border-top-left-radius: 20px 50px;
  border-top-right-radius: 20px 50px;
  border-bottom-right-radius: 20px 50px;
  border-bottom-left-radius: 20px 50px;
  cursor: pointer;
}
.demo-buttons a.current-demo {
  background: #c94e50;
  color: #fffce1;
}
.side-button {
  display: inline-block;
  width: 5em;
  height: 2.5em;
  line-height: 2.5em;
  cursor: pointer;
  background: #fffce1;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 800;
}
.side-button.left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.side-button.right {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.side-button.active {
  background: #c94e50;
  color: #fffce1;
}
@media screen and (max-width: 40em) {
  main {
    font-size: 80%;
  }
  h1 {
    font-size: 2.5em;
  }
  .demo-buttons {
    max-width: 900px;
    padding: 0 2em;
  }
}
.bm-burger-button {
  position: fixed;
  font: normal normal normal 14px/1 Helvetica;
  width: 36px;
  height: 30px;
  left: 2px;
  top: 2px;
}
.right .bm-burger-button {
  left: initial;
  right: 1px;
}
.bm-burger-bars {
  background: #373a47;
}
.bm-morph-shape {
  fill: #373a47;
}
.bm-menu {
  background: #373a47;
}
.bm-menu a {
  color: #b8b7ad;
}
.bm-menu a:hover,
.bm-menu a:focus {
  color: #c94e50;
}
.bm-item-list a {
  padding: 0.8em;
}
.bm-item-list a span {
  margin-left: 10px;
  font-weight: 700;
  white-space: nowrap;
}
#slide .bm-cross,
#stack .bm-cross {
  background: #bdc3c7;
}
#slide .bm-menu,
#stack .bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #134C91;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list { 
  color: #b8b7ad;
  padding: 0.8em;
}

.bm-menu-wrap {
  bottom: 0;
  left: 0;
}

#temptable {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#temptable td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

#temptable tr:nth-child(even) {
  background-color: #dddddd;
}
.summarySearch {
  width: 100%;
}
.datePicker {
  width: 150px;
  display:inline-block;
  vertical-align: bottom;
}
.templogsearch {
  width: 150px;
  float:left;
  vertical-align: bottom;
}
.templogtable {
  width: 900px;
  padding-left:100px;
  display:inline-block;
  vertical-align: top;
}
.dropdownButton {
  width: 250px;
  display:inline-block;
  vertical-align: bottom;
  padding-left: 20px;
}
.dropdownSearch {
  width: 300px;
  display:inline-block;
  vertical-align: bottom;
}
.dropdownEventSearch {
  width: 270px;
  display:inline-block;
  vertical-align: bottom;
}
.togglebuttonSearch {
  width: 950px;
  display:inline-block;
  vertical-align: bottom;
}
.buttonSearch {
  width: 200px;
  display:inline-block;
  vertical-align: bottom;
}
.profileImage {
  padding-right: 20px;
}

.gordoneer {
  min-width: 1000px;
}
.routific {
  float:left;
}
.filtrera {
  float:right;
}

.bm-menu {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}