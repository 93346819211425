
.zipcodes__listNames {

    height: 100%;
  

}

.zipcodes__listNames__item {
    margin-bottom: 10px;
    margin-right: 10px;
    display: inline-block;
    padding: 5px;
    border-radius: 2.5px;
    border: 1px solid grey;
    font-size: 12px;
    cursor: default;
}

.zipcodes__listNames__item__active {
    margin-bottom: 10px;
    margin-right: 10px;
    display: inline-block;
    padding: 5px;
    border-radius: 2.5px;
    border: 1px solid grey;
    background-color: darkgrey;
    color: white;
    font-size: 12px;
    cursor: default;
}


